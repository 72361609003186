import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { Box } from "@chakra-ui/react";

const getData = graphql`
  {
    placeholderImage: file(relativePath: { eq: "historybanner.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

const HistoryBanner = ({
  overlay,
  overlayOpacity,
  backgroundPosition,
  children,
  ...props
}) => {
  const data = useStaticQuery(getData);
  const pluginImage = getImage(data.placeholderImage);
  return (
    <Box
      as={BgImage}
      backgroundPosition={backgroundPosition || "50% 50%"}
      image={pluginImage}
      py="8rem"
      {...props}
    >
      <Box
        w="100%"
        h="100%"
        bg={overlay || "#000"}
        opacity={overlayOpacity || "0.5"}
        position="absolute"
        inset="0"
        zIndex="104"
      />
      <Box position="relative" zIndex="106">
        {children}
      </Box>
    </Box>
  );
};

export default HistoryBanner;
