import React from "react";
import Layout from "src/components/Layout";
import HeadSEO from "src/components/HeadSEO";
import { Box, Heading, VStack } from "@chakra-ui/react";
import Container from "src/components/Container";
import HistoryBanner from "src/components/banners/HistoryBanner";
import { graphql, useStaticQuery } from "gatsby";
import ReactMarkdown from "react-markdown";

const getData = graphql`
  {
    markdownRemark(fields: { slug: { eq: "/pages/history/" } }) {
      frontmatter {
        banner_text
        section_one
        section_two
        section_three
      }
    }
  }
`;

const History = () => {
  const data = useStaticQuery(getData);
  const page = data.markdownRemark.frontmatter;
  return (
    <Layout>
      <HeadSEO
        title="History | Evandale Village Fair"
        description="Evandale has attracted penny farthing enthusiasts
              from all states of Australia and from New Zealand, USA, England,
              Ireland, Holland, Germany, Czech Republic, Sweden, Singapore and
              Japan."
        slug="/history/"
      />
      <HistoryBanner
        overlayOpacity="0.75"
        overlay="#223843"
        backgroundPosition="65% 60%"
      >
        <Container textAlign="center" size="sm" color="white">
          <VStack spacing="1.5rem">
            <Heading as="h1" size="2xl">
              {page.banner_text}
            </Heading>
          </VStack>
        </Container>
      </HistoryBanner>
      <Box as="section">
        <Container size="md" py="6rem">
          <VStack align="flex-start" spacing="2rem">
            <ReactMarkdown children={page.section_one} />
          </VStack>
        </Container>
      </Box>
      <Box as="section" color="white" bg="teal.700">
        <Container size="md" py="6rem">
          <VStack align="flex-start" spacing="2rem">
            <ReactMarkdown children={page.section_two} />
          </VStack>
        </Container>
      </Box>
      <Box as="section">
        <Container size="md" py="6rem">
          <VStack align="flex-start" spacing="2rem">
            <ReactMarkdown children={page.section_three} />
          </VStack>
        </Container>
      </Box>
    </Layout>
  );
};

export default History;
